<template lang="pug">
div
  van-form
    van-cell-group(v-for="(g, gIndex) of form.groups" :key="gIndex")
      component(v-for="(vf, vfIndex) of g.fields"
        :key="vfIndex" 
        :is="vf.component ? vf.component : 'van-field'"
        :label="vf.name" 
        v-model="data[vf.key]" 
        v-bind="vf.attrs" 
        @click="vf.click ? vf.click($event, vf) : ''"
        @callback="vf.callback ? vf.callback($event, vf) : ''"
      )
    van-row.row
      van-button.col(type='info' block round  @click="onSubmit" :disabled='commitState') 提交
  van-action-sheet(v-if='showClient' v-model="showClient" title="选择客户" )
    client(:is-component="true" @onSelect="onSelectClient" @onCancel='showClient=false')
</template>

<script>

import {
  vantToDpProvince, 
  vantToDpCity, 
  vantToDpDistrict,
  getVantAddress
} from '../../lib/helpers/AreaHelper'
import formmixin from '../../components/form'


import client from './client'
import FirstPartyContact from '../../components/other/FirstPartyContact'

export default {
  mixins: [formmixin],
  components: {
    client,
    FirstPartyContact
  },
  data() {
    return {
      showClient: false,
      commitState: false,
      form: {
        groups: [
          {
            fields: [
              {
                name: '报备人',
                key: 'projectReportman',
                attrs: {
                  readonly: true
                }
              },
              {
                name: '项目名称',
                key: 'name',
                attrs: {
                  required: true,
                  rules: [
                    {required: true, message: '请输入项目名称'}
                  ]
                }
              },
              // {
              //   name: '甲方名称',
              //   key: 'first_party_name',
              //   attrs: {
              //     rules: [{required: true, message: '请输入甲方名称'}]
              //   },
              //   click: ()=>{
              //     this.showClient = true
              //   }
              // },
              {
                name: '甲方联系人',
                // key: 'firstPartyInfo', // 不需要绑定数据
                component: 'FirstPartyContact',
                attrs: {
                  isComponent: true,
                },
                callback: (data)=>{
                  this.data.firstPartyInfo = data
                }
              },
              { 
                name: '预招标时间：', 
                key: 'invitation_time' ,
                component: 'PickerData',
                attrs: {
                  required: true,
                  rules: [
                    {required: true, message: '请选择项目预招标时间'}
                  ]
                }
              },
              { 
                name: '预交付时间：', 
                key: 'delivery_time',
                component: 'PickerData',
                attrs: {
                  required: true,
                  rules: [
                    {required: true, message: '请选择项目预交付时间'}
                  ]
                }
              },
              {
                name: '项目所在地区',
                key: 'area',
                component: 'AreaField',
              },
              {
                name: '详细地址',
                key: 'address'
              },
              {
                name: '预计金额',
                key: 'amount',
                component: 'SuffixField',
                attrs: {
                  type: 'number',
                  suffix:'万元'
                }
              },
              {
                name: '简单描述',
                key: 'sketch',
                attrs: {
                  type: 'textarea'
                }
              },
              {
                name: '项目可行性',
                key: 'feasibility',
                component: 'RateField'
              },
              {
                name: '项目有效期',
                key: 'validity',
                component: 'PickerField',
                attrs: {
                  type: 'constant',
                  state_type: 'report_valtime'
                }
              },
              {
                name: '添加备注',
                key: 'remark',
                attrs: {
                  autosize: true,
                  type: 'textarea'
                }
              },
              {
                name: '添加附件',
                key: 'projectAttachment',
                component: 'UploaderField'
              }
            ]
          }
        ]
      },
      data: {
        name: '',
        firstPartyInfo: null,
        amount: '',
        intro: '',
        feasibility: 3,
        validity: 'three_month',
        invitation_time: 0,
        delivery_time: 0,
        remark: '',
        province: '',
        city: '',
        district: '',
        address: '',
        projectReportman: (this.userInfo || {}).ui_name,
        projectAttachment: [],
        sketch: '',
        area: [],
      }
    }
  },
  computed: {
    userInfo() {
      let userInfo = this.$store.state.userInfo
      return userInfo
    },
    validityConstant() {
      return this.$store.getters.GetConst('report_valtime')
    },
    validityOpts() {
      let result = Object.values(this.validityConstant)
      return result
    },
    comInfo() {
      return {
        st_id_poi_company_stores: (this.$store.state.userInfo.sell_users || {}).st_id_poi_company_stores || 0,
        com_id_poi_companys: this.$store.state.companyInfo.id
      }
    },
  },
  watch: {
    userInfo(user) {
      this.data.projectReportman = user.ui_name
    }
  },
  methods: {
    async onSubmit() {
      if(!this.data.name) {
        this.$toast('请输入项目名称')
        return
      }
       if(!this.data.firstPartyInfo) {
        this.$toast('请选择甲方')
        return
      }
      if(this.data.invitation_time == 0 || this.data.delivery_time == 0) {
        this.$toast('请选择项目预招标时间和预交付时间')
        return
      }
      
      this.commitState = true
      let province_poi_province = (await vantToDpProvince(this, this.data.area[0].name)).id
      let city_poi_city = (await vantToDpCity(this, this.data.area[1].name, province_poi_province)).id
      let district_poi_district = (await vantToDpDistrict(this, this.data.area[2].name, city_poi_city)).id

      // 附件
      let projectAttachment = this.data.projectAttachment.map((file) => {
        return {
          file_url: file.url,
          id: 0,
          delete: 'no'
        }
      })

      // 报备人信息
      let project_reportman = {
        user_poi_reportman: 0,
        name: this.userInfo.ui_name,
        is_self: 'yes',
        tel: this.userInfo.u_mobile,
        email: this.userInfo.u_email,
        royalties_expectation: '',
        project_relation: '',
        strengths: ''
      }
      let first_party_info = {}
      if(this.data.firstPartyInfo && this.data.firstPartyInfo.ofc_cus_companys) {
        let ofc_com_id = this.data.firstPartyInfo.ofc_cus_companys.id
       
        let project_first_party_linkman =[]
        let firstLinkman = this.data.firstPartyInfo.first_ofc_linkman
        if(firstLinkman) {
          project_first_party_linkman.push({
            id: 0,
            name: firstLinkman.name,
            tel: firstLinkman.tel,
            id_poi_ofc_cus_companys: ofc_com_id,
            id_poi_ofc_linkman: firstLinkman.id,
            job: firstLinkman.job || '',
            delete: 'no'
          }) 
        }
        let secondLinkman = this.data.firstPartyInfo.second_ofc_linkman
        if(secondLinkman) {
          project_first_party_linkman.push({
            id: 0,
            name: secondLinkman.name,
            tel: secondLinkman.tel,
            id_poi_ofc_cus_companys: ofc_com_id,
            id_poi_ofc_linkman: secondLinkman.id,
            job: secondLinkman.job || '',
            delete: 'no'
          }) 
        }
        first_party_info = {
          project_first_party_linkman: project_first_party_linkman,
          id_poi_ofc_cus_companys: ofc_com_id,
          first_party_name: this.data.firstPartyInfo.ofc_cus_companys.fullname || this.data.firstPartyInfo.ofc_cus_companys.spname
        }
      }
      this.data.firstPartyInfo = null
      this.data = {
        ...first_party_info,
        ...this.data,
        seller_id: this.userInfo.seller_poi_sell_users,
        st_id_poi_company_stores: this.comInfo.st_id_poi_company_stores,
        com_id_poi_companys: this.comInfo.com_id_poi_companys,
        dealer_id_poi_company_dealer: 0,
        province_poi_province,
        city_poi_city,
        district_poi_district,
        project_attachment: projectAttachment,
        project_reportman: project_reportman,
        // project_first_party_linkman: JSON.stringify(this.data.projectFirstPartyLinkman)
      }
      let params = {
        ...this.data
      }
      try {
        await this.$api.FastCreateProject(params)
        this.$toast('快速报备成功')
        this.$router.push({name: 'project'})
        this.commitState = false
      } catch(e) {
        this.$toast(this.$error(e))
        this.commitState = false
      }
    },
    onSelectClient(client) {
      this.data.id_poi_ofc_cus_companys = client
      this.data.first_party_name = client.fullname || client.spname
      this.showClient = false
    }
  },
  mounted() {
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.back(-1)
      }
    })
    let area = getVantAddress("", "","")
    this.data.area = area
    this.data.projectReportman = this.userInfo.ui_name
  }
}
</script>

<style lang="less" scoped>
.row {
  margin: 0px 10px;
}
.col {
  margin: 10px 0px;
}
</style>