<template lang="pug">
div
  pull-refresh-list(
    :getListMethod="getListData"
    v-on:update:list="list = $event"
    )
    van-contact-list(v-model='chosenContactId', :list='list', @add='onAdd', @edit='onEdit', @select='onSelect')
  van-popup(v-model='showEdit', position='bottom')
    van-contact-edit(:contact-info='editingContact', :is-edit='isEdit', @save='onSave', @delete='onDelete' show-set-default =true, set-default-label= '设为默认联系人' )
</template>

<script>
export default {
  props: ['value', 'isComponent'],
  data() {
    return {
      chosenContactId: null,
      customer_id: 0,
      editingContact: {},
      showEdit: false,
      isEdit: false,
      list: [
      ]
    }
  },
  watch:{
    value(newW){
     this.customer_id = newW
    }
  },
  methods: {
    async getListData() {
      // params = {
      //   ...params,
      //   customer_id: this.customer_id
      // }
      let res = await this.$api.GetContact(this.customer_id)
      this.list = this.list.concat(res.data.items.map((item) => {
        return {
          id: item.id,
          name: item.name,
          tel: item.mobile,
          isDefault: item.is_default  === 'FALSE' ? false : true 
        }
      }))
      return res.data.items.length
    },
    onAdd() {
      this.editingContact = { id: this.list.length }
      this.isEdit = false;
      this.showEdit = true;
      // console.log('onadd',this.editingContact)
    },

    // 编辑联系人
    onEdit(item) {
      this.isEdit = true
      this.showEdit = true
      this.editingContact = item
      // console.log('edit',item)
    },
    // 选中联系人
    onSelect(item) {
      if (this.isComponent) {
        this.$emit('onSelect', item)
      } 
    },

    // 保存联系人
    async onSave(info) {
      this.showEdit = false
      let param = {
          name: info.name,
          mobile: info.tel,
          is_default: info.isDefault ? '1' : '0',
          cus_com_poi_ofc_cus_companys: this.customer_id
      }
      // put 
      if (this.isEdit) {
        param.id = info.id
        let data = JSON.stringify(param)
        try {
          await this.$api.PutContact(data)
          this.$toast('修改成功')
          if(info.isDefault){
            this.list.forEach((item) => {
              if(item.id != info.id){
                item.isDefault = false
              }
            })
          }
        } catch (e) {
          this.$toast(this.$error(e))
        }
        this.list = this.list.map((item) =>
          item.id === info.id ? info : item
        )
      } else { // post 
        // console.log('post',param)
        let data = JSON.stringify(param)
        try {
          let res = await this.$api.PostContact(data)
          this.$toast('添加成功',res)
          info.id = res.data.id
          this.list.push(info)
          if(info.isDefault){
            this.list.forEach((item) => {
              if(item.id != info.id){
                item.isDefault = false
              }
            })
          }
        } catch (e) {
          this.$toast(this.$error(e))
        }
      }
      this.chosenContactId = info.id
      // console.log('save',info)
    },

    async onDelete(info) {
      this.showEdit = false
      let param = { id: info.id }
      try {
        await this.$api.DeleteContact(param)
        this.list = this.list.filter((item) => item.id !== info.id)
      } catch (e) {
        this.$toast(this.$error(e))
      }
      if (this.chosenContactId === info.id) {
        this.chosenContactId = null
      }
    },
  },
  created() {
    this.customer_id = this.value
  }
}
</script>