<template lang="pug">
div
  van-field(v-for="(field, index) of fields" 
    :key='index'
    :label="field.label",
    v-bind='field.attrs'
    :value="(fields_data[field.key] || {}).text"
    @click="(field.click && isComponent) ? field.click($event, field) : ''"
    :disabled='!isComponent'
  )
    template(#right-icon)
      van-icon(v-if="isComponent && field.template && fields_data[field.key]"  name='close' @click="onDeleteLinkman($event, field.key)")
  van-action-sheet(v-if='showClient' v-model="showClient" title="选择客户" )
    client( :is-component="true" @onSelect="onSelectClient" @onCancel='showClient=false')
  van-action-sheet(v-if='showLinkman' v-model="showLinkman" title="选择联系人")
    contactList(:value='fields_data.ofc_cus_companys.id' :isComponent='true' @onSelect="onSelectLinkman")
  div(v-if="submit" style='margin: 10px')
    van-button.col(type='info' native-type='submit' block round  @click='onSave') 保存
</template>
<script>

import contactList from '../../views/hall/_ContactList'
import client from '../../views/hall/client'

export default {
  // submit 是否需要提交
  props: ['value', 'isComponent', 'submit'],
  name: 'FirstPartyContact',
  components: {
    contactList,
    client
  },
  data() {
    return {
      showClient: false,
      showLinkman: false,
      isfirst: false,
      fields_data:{
        ofc_cus_companys: null,
        first_ofc_linkman: null,  
        second_ofc_linkman: null,
      },
      fields: [
        {
          label: '甲方名称',
          key: 'ofc_cus_companys',
          click: ()=>{
            this.showClient = true
          },
          attrs: {
            required:true,
            rules: [
              {required: true, message: '请选择甲方名称'}
            ]
          }
        },
        {
          label: '第1联系人',
          key: 'first_ofc_linkman',
          template: true,
          click: ()=>{
            this.onEditLinkman(true)
          }
        },
        {
          label: '第2联系人',
          key: 'second_ofc_linkman',
          template: true,
          click: ()=>{
           this.onEditLinkman(false)
          }
        }
      ],
    
    }
  },
  watch: {
    value(newV) {
      this.init(newV)
    }
  },
  methods: {
    init(obj) {
      this.fields_data.ofc_cus_companys = null
      this.fields_data.first_ofc_linkman = null
      this.fields_data.second_ofc_linkman = null
      if(obj) {
        let {ofc_cus_companys, linkman} = obj
        this.fields_data.ofc_cus_companys = ofc_cus_companys
        if(linkman && linkman.length > 0) {
          this.fields_data.first_ofc_linkman = linkman[0]
          if(linkman[0].id_poi_ofc_linkman && linkman[0].id_poi_ofc_linkman.name) {
            this.fields_data.first_ofc_linkman.text = `${linkman[0].id_poi_ofc_linkman.name} / ${linkman[0].id_poi_ofc_linkman.mobile}`
          } else {
            this.fields_data.first_ofc_linkman.text = `${linkman[0].name} / ${linkman[0].tel}`
          }
          if(linkman.length > 1) {
            this.fields_data.second_ofc_linkman = linkman[1]
            if(linkman[0].id_poi_ofc_linkman && linkman[0].id_poi_ofc_linkman.name) {
              this.fields_data.second_ofc_linkman.text = `${linkman[1].id_poi_ofc_linkman.name} / ${linkman[1].id_poi_ofc_linkman.mobile}`
            } else {
              this.fields_data.second_ofc_linkman.text = `${linkman[1].name} / ${linkman[1].tel}`
            }
          }
        }
      }
    },
    onEditLinkman(first) {
      this.isfirst = first
      if(!this.fields_data.ofc_cus_companys || this.fields_data.ofc_cus_companys.id == 0) {
        this.$dialog.alert({
          title: '',
          message: '请先选择客户',
        }).then(() => {
        })
        return 
      }
      this.showLinkman = true
    },
    onSelectClient(client) {
      this.showClient = false
      // 清空联系人
      if(this.fields_data.ofc_cus_companys && this.fields_data.ofc_cus_companys.id != client.id) {
        this.fields_data.first_ofc_linkman = null
        this.fields_data.second_ofc_linkman = null
      }
      this.fields_data.ofc_cus_companys = client
      this.fields_data.ofc_cus_companys.text = client.fullname || client.spname
      // console.log(client)
      this.$emit('callback', this.fields_data)
    },
    onSelectLinkman(linkman) {
      if(!this.compareLinkman(linkman)) {
          this.$dialog.alert({
          title: '',
          message: '请选择其他联系人',
        }).then(() => {
        })
        return  
      }
      this.showLinkman = false
      let tempData = {
        ...linkman,
        text: `${linkman.name} / ${linkman.tel}`,
        id_poi_ofc_linkman: linkman.id,
        id_poi_ofc_cus_companys: this.fields_data.ofc_cus_companys.id
      }
      if (this.isfirst) {
        this.fields_data.first_ofc_linkman = tempData
      }
      else {
        this.fields_data.second_ofc_linkman = tempData
      }
      // console.log(linkman)
      this.$emit('callback', this.fields_data)
    },
    compareLinkman(linkman) {
      return linkman.id != (this.fields_data.first_ofc_linkman || {}).id  && linkman.id != (this.fields_data.second_ofc_linkman || {}).id
    },
    onDeleteLinkman(e, key) {
      e.stopPropagation()
      this.fields_data[key] = null
      this.$emit('callback', this.fields_data)
    },
    onSave() {
      // console.log('保存甲方联系人信息')
      this.$emit('onSave', this.fields_data)
    }
  },
  mounted() {
    this.init(this.value)
  }
}
</script>